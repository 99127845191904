<template>
	<div>
		<el-form :model="ruleForm" :inline="true">
			<el-form-item label="版本名称">
				<el-input v-model="ruleForm.versionNo" placeholder="版本名称"></el-input>
			</el-form-item>
			<el-form-item>
				<div class="block">
					<span class="demonstration" style="margin-right: 10px">时间</span>
					<el-date-picker v-model="time" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
						value-format="yyyy-MM-dd HH:mm:ss" @change="changeTime">
					</el-date-picker>
				</div>
			</el-form-item>

			<el-form-item>
				<el-button @click="getInfo" type="primary">搜索</el-button>
			</el-form-item>
			<!-- <span style="">请选择上传平台：</span>
			<div style="display: inline-block;">
				<el-radio-group v-model="radio1">
					<el-radio-button label="华为"></el-radio-button>
					<el-radio-button label="三星"></el-radio-button>
					<el-radio-button label="OPPO"></el-radio-button>
					<el-radio-button label="小米"></el-radio-button>
					<el-radio-button label="vivo"></el-radio-button>
				</el-radio-group>
			</div> -->
			<el-form-item v-if="User == 'true'">
				<el-button type="primary" style="margin-left: 20px" @click="handleUp">更新APP</el-button>
			</el-form-item>
		</el-form>
		<el-table max-height="510px" :data="tableData" stripe style="width: 100%;min-height: 510px">
			<el-table-column prop="versionNo" width="160px" label="版本名称"></el-table-column>
			<el-table-column prop="id" label="appid" width="120px"></el-table-column>
			<!-- <el-table-column label="二维码" width="150px">
				<template slot-scope="scope">
					<el-image style="width: 50px; height: 50px" :src="scope.row.qrCode"
						:preview-src-list="[scope.row.qrCode]"></el-image>
				</template>
			</el-table-column> -->
			<el-table-column prop="huaweiUrl" label="下载地址"></el-table-column>
			<el-table-column prop="gmtCreate" label="创建时间" width="200px"></el-table-column>
			<el-table-column v-if="User == 'true'" label="操作" width="120">
				<template slot-scope="scope">
					<el-button @click="edit(scope.row)" type="text" style="color: #409eff" size="small">修改</el-button>
					<el-button @click="del(scope.row)" type="text" style="color: red;" size="small">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="block" style="display: flex;justify-content: flex-start;margin-top: 20px">

			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
				:page-sizes="[10, 20, 50, 100]" :page-size="ruleForm.size"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		<!--  修改信息Dialog  -->
		<el-dialog :visible.sync="dialogVisible" width="900px" center>
			<div style="font-size: 24px;justify-content: center" slot="title">修改信息</div>
			<el-form :model="obj" label-width="100px">
				<!-- <el-form-item label="下载链接">
          <el-input v-model="editForm.appUrl"></el-input>
        </el-form-item>
        <el-form-item label="appid">
          <el-input v-model="editForm.id"></el-input>
        </el-form-item> -->
				<el-form-item label="版本名称">
					<el-input v-model="obj.versionNo"></el-input>
				</el-form-item>

				<el-button type="primary" style="margin-left: 20px" @click="upapp(0)">修改华为</el-button>

				<el-button type="primary" style="margin-left: 20px" @click="upapp(1)">修改三星</el-button>


				<el-button type="primary" style="margin-left: 20px" @click="upapp(2)">修改小米</el-button>

				<el-button type="primary" style="margin-left: 20px" @click="upapp(3)">修改OPPO</el-button>

				<el-button type="primary" style="margin-left: 20px" @click="upapp(4)">修改vivo</el-button>
				<el-form-item label="已修改apk:">
					<div style="height: 30px; color: #42B983;">
						<!-- 123 -->
						<span v-if="app.hw">已修改华为 </span>
						<span v-if="app.op">已修改OPPO </span>
						<span v-if="app.ss">已修改三星 </span>
						<span v-if="app.vo">已修改vivo </span>
						<span v-if="app.xm">已修改小米 </span>
					</div>
				</el-form-item>
				<!-- <el-form :model="editForm.appUrl" label-width="100px"> -->
				<!--  <el-form-item v-if="false" label="下载链接">
		          <el-input v-model="editForm.appUrl"></el-input>
		        </el-form-item> -->
				<!-- <el-form-item :model="editForm.appUrl" label="上传">
					<el-upload class="upload-demo" drag :action="xxUrl+'/upload/file'" multiple
						:on-success="upSuccessED" :headers="{'token': token}" ref="myUpload">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div v-if="false" class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
					</el-upload>
				</el-form-item> -->
			</el-form>
			<div style="display: flex;justify-content: center">
				<el-button type="primary" @click="editApp">修改</el-button>
				<el-button @click="dialogVisible = false">取消</el-button>
			</div>
			</el-form>
		</el-dialog>
		<!--  app  -->
		<el-dialog :visible.sync="dialogVisible1" width="900px" center>
			<!-- <div style="font-size: 24px;justify-content: center" slot="title">修改信息</div> -->
			<el-form :model="addFrom" label-width="100px">
				<el-form-item :model="addFrom.appUrl" label="上传">
					<el-upload class="upload-demo" drag :action="xxUrl+'/upload/file'" multiple :on-success="upSuccess"
						:headers="{'token': token}" ref="myUpload">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div v-if="false" class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
					</el-upload>
				</el-form-item>
			</el-form>
			<div style="display: flex;justify-content: center">
				<el-button type="primary" @click="addNapp">确定</el-button>
				<el-button @click="dialogVisible1 = false">取消</el-button>
			</div>
			</el-form>
		</el-dialog>
		<!--  添加APPDialog  -->
		<!-- <el-dialog :visible.sync="dialogVisible2" width="900px" center>
			<div style="font-size: 24px;justify-content: center" slot="title">上传APP</div>
			<div slot="footer">
				<el-form :model="addFrom" label-width="100px">
					<el-form-item v-if="false" label="下载链接">
						<el-input v-model="addFrom.appUrl"></el-input>
					</el-form-item>
					<el-form-item v-model="addFrom.appUrl" label="上传">
						<el-upload class="upload-demo" drag :action="xxUrl+'/upload/file'" multiple
							:on-success="upSuccess" :headers="{'token': token}" ref="myUpload">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							<div v-if="false" class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="版本名称">
						<el-input v-model="addFrom.versionName"></el-input>
					</el-form-item>
					<div style="display: flex;justify-content: center">
						<el-button type="primary" @click="upApp">确定</el-button>
						<el-button @click="dialogVisible2 = false">取消</el-button>
					</div>
				</el-form>
			</div>


		</el-dialog> -->
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		name: "AppList1",
		data() {
			return {
				app: {
					hw: false,
					ss: false,
					op: false,
					xm: false,
					vo: false
				},
				num: null,
				obj: {
					"huaweiUrl": "",
					"oppoUrl": "",
					"samsungUrl": "",
					"updateContent": "",
					"versionNo": '',
					"vivoUrl": "",
					"xiaomiUrl": ""
				},
				radio1: '华为',
				User: '',
				xxUrl: global.posturl.host,
				dialogVisible: false,
				dialogVisible1: false,
				editForm: {
					appUrl: '',
					id: '',
					versionNo: ''
				},

				dialogVisible2: false,
				addFrom: {
					versionNo: '',
					appUrl: ''
				},
				ruleForm: {
					"beginTime": "",
					"endTime": "",
					"page": 1,
					"size": 10,
					'versionNo': '',
				},
				total: 0,
				tableData: [],
				time: '',
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			}
		},

		created() {
			this.User = sessionStorage.getItem('User')
			this.getInfo()
			console.log(this.$refs)
		},
		computed: {
			token() {
				return sessionStorage.getItem("token")
			}

		},
		methods: {
			upapp(id) {
				this.dialogVisible1 = true
				this.clearFiles
				this.addFrom.appUrl = ''
				this.num = id
				console.log(this.editForm, 'editForm!!!!!');
				console.log(this.obj, 'obj!!!!!!');
			},
			addNapp() {
				// console.log(this.obj);
				if (this.addFrom.appUrl == '') {
					this.$message({
						message: '请仔细检查表单内容',
						type: 'warning'
					});
				} else {
					if (this.num == 0) {
						this.obj.huaweiUrl = this.addFrom.appUrl
						console.log(this.obj);
						this.dialogVisible1 = false
						this.app.hw = true
					} else if (this.num == 1) {
						this.obj.samsungUrl = this.addFrom.appUrl
						console.log(this.obj);
						this.dialogVisible1 = false
						this.app.xm = true
					} else if (this.num == 2) {
						this.obj.xiaomiUrl = this.addFrom.appUrl
						console.log(this.obj);
						this.dialogVisible1 = false
						this.app.op = true
					} else if (this.num == 3) {
						this.obj.oppoUrl = this.addFrom.appUrl
						console.log(this.obj);
						this.dialogVisible1 = false
						this.app.ss = true
					} else if (this.num == 4) {
						this.obj.vivoUrl = this.addFrom.appUrl
						console.log(this.obj);
						this.dialogVisible1 = false
						this.app.vo = true
					}
					console.log(this.obj1, 'editForm!!!!!');
					console.log(this.obj, 'obj!!!!!!');
				}
			},
			getInfo() {
				this.$ajax('addapppage', 'post', this.ruleForm).then(res => {
					console.log(res, '添加app')
					this.tableData = res.data.records
					this.total = res.data.total
				})
			},
			changeTime(time) {
				console.log(time)
				if (time) {
					this.ruleForm.beginTime = time[0]
					this.ruleForm.endTime = time[1]
				} else {
					this.ruleForm.beginTime = ''
					this.ruleForm.endTime = ''
				}
				this.getInfo()
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.ruleForm.size = val
				this.getInfo()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.ruleForm.page = val
				this.getInfo()
			},
			edit(row) {
				this.app = {
					hw: false,
					ss: false,
					op: false,
					xm: false,
					vo: false
				}
				console.log(row,'row!!!!!!!')
				this.dialogVisible = true
				this.editForm = row
				this.obj = row
				this.obj1 = row
				// console.log(this.editForm,'');
			},
			// 删除
			del(row) {
				console.log(row, 'row！！！！！！！！')
				this.$confirm('此操作将永久删除该文件是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					axios.get('https://charge.cheersofa.com/app/version/delete/' + row.id).then(
						response => { //successcallback
							console.log(response.data) // 得到返回结果数据
							this.getInfo()
							this.$message({
								type: 'success',
								message: '删除成功'
							});
						}).catch(
						error => {
							console.log(error.message) //错误信息
						})
					// axios.delete(global.posturl.host + '/app/version/delete/' + row.id, {
					// 	headers: {
					// 		'token': sessionStorage.getItem('token')
					// 	},
					// }).then(res => {
					// 	console.log(res,'删除成功')
					// 	this.getInfo()
					// 	this.$message({
					// 		type: 'success',
					// 		message: '删除成功'
					// 	});
					// })
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			editApp1() {
				console.log('确定');
			},
			editApp() {
				this.$ajax('updatenewapp', 'post', this.obj).then(res => {
					console.log(res)
					if (res.code == 2000) {
						this.$message({
							message: '修改信息成功',
							type: 'success'
						});
						this.dialogVisible = false
						this.getInfo()
					} else {
						this.$message({
							message: res.message,
							type: 'warning'
						});
					}
				})
			},
			// upSuccessED(response, file, fileList) {
			// 	console.log(response, file, fileList)
			// 	this.editForm.appUrl = response.data.link
			// },
			// 上传APP成功
			upSuccess(response, file, fileList) {
				console.log(response, file, fileList)
				this.addFrom.appUrl = response.data.link
			},
			// 提交上传
			upApp() {
				if (this.addFrom.appUrl == '' || this.addFrom.versionName == '') {
					this.$message({
						message: '请仔细检查表单内容',
						type: 'warning'
					});
				} else {
					let obj = {
						"huaweiUrl": "",
						"oppoUrl": "",
						"samsungUrl": "",
						"updateContent": "",
						"versionNo": this.addFrom.versionName,
						"vivoUrl": "",
						"xiaomiUrl": ""
					}
					if (radio1 == '华为') {
						obj.huaweiUrl = this.addFrom.appUrl
					}
					this.$ajax('appAdd', 'post', this.addFrom).then(res => {
						console.log(res)
						this.$message({
							message: '上传成功',
							type: 'success'
						});
						this.dialogVisible2 = false
						this.getInfo()
					})
				}
			},
			handleUp() {
				this.$router.push('UpdatePushApp')
				// console.log(this.radio1,'radio1!!!');
				return
				// this.dialogVisible2 = true
				// this.$refs.myUpload.clearFiles()
				// this.addFrom.versionName = ''
				// this.addFrom.appUrl = ''
			},
		}
	}
</script>

<style scoped lang="scss">
	.upload-demo {
		display: flex;
		justify-content: flex-start;
	}
</style>
